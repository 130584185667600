import { template as template_e530fd12da3243568dd3800acafaa102 } from "@ember/template-compiler";
const FKText = template_e530fd12da3243568dd3800acafaa102(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
