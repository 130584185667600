import { template as template_c5951e806f414b0081975cde834a617c } from "@ember/template-compiler";
const FKControlMenuContainer = template_c5951e806f414b0081975cde834a617c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
